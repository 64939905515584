import axios from 'axios';
const devMode = false;

// Function to get API URL
export const getAPIURL = () => {
    if (devMode) {
        return 'http://198.244.148.105:5000';
    } else {
        return 'https://brendanleet.me:8443';
    }
}

export const handleError = (error, errorMessage) => {
    console.error(errorMessage, error);
    return errorMessage;
};

// Function to verify the token
export const verifyToken = async (token, navigate) => {
    if (!token) {
        navigate('/login');
        return { isVerified: false, errorMessage: 'Invalid Login Token.' };
    }
    try {
        const response = await axios.get(`${getAPIURL()}/verify-token`, {
            headers: { 'x-access-token': token }
        });
        if (response.status === 200) {
            return { isVerified: true, errorMessage: '' };
        }
    } catch (error) {
        return { isVerified: false, errorMessage: 'Invalid Login Token.' };
    }
    return { isVerified: false, errorMessage: 'Invalid Login Token.' };
};

// Function to check if the user is an admin
export const checkIsUserAdmin = async (token, navigate) => {
    if (!token) {
        navigate('/login');
        return { isAdmin: false, errorMessage: 'No token provided' };
    }
    try {
        const response = await axios.get(`${getAPIURL()}/me`, {
            headers: { 'x-access-token': token }
        });
        return { isAdmin: response.data.is_admin, errorMessage: '' };
    } catch (error) {
        return { isAdmin: false, errorMessage: 'Failed to verify admin status.' };
    }
};


// Function to fetch data from an endpoint
export const fetchData = async (endpoint, token) => {
    try {
        const response = await axios.get(`${getAPIURL()}${endpoint}`, {
            headers: { 'x-access-token': token }
        });

        return response.data;
    } catch (error) {
        const errorMessage = handleError(error, `Failed to fetch data from ${endpoint}.`);
        console.error(errorMessage);
        throw new Error(errorMessage);
    }
};

// Function to handle actions (POST, DELETE, etc.)
export const handleAction = async (endpoint, method, data, successMessage, refreshData, token) => {
    try {
        await axios({ method, url: `${getAPIURL()}${endpoint}`, headers: { 'x-access-token': token }, data });
        const result = { successMessage };
        if (refreshData) {
            await refreshData();
        }
        return result;
    } catch (error) {
        const errorMessage = handleError(error, 'Failed to perform action.');
        return { errorMessage };
    }
};
