import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { fetchData, verifyToken } from '../utils/apiUtils';
import AlertBox from '../components/AlertBox';

const Dashboard = () => {
    const [shops, setShops] = useState([]);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchShops = async () => {
            // Verify the token
            const { isVerified, errorMessage } = await verifyToken(token, navigate);
            if (!isVerified) {
                setMessage(errorMessage);
                setShowAlert(true);
                return;
            }

            // Fetch the shops data
            const result = await fetchData('/shops', token);
            if (result.errorMessage) {
                setMessage(result.errorMessage);
                setShowAlert(true);
            } else {
                setShops(result);
            }
        };

        fetchShops();
    }, [token, navigate]);

    const handleViewShop = (shopName) => {
        navigate(`/shop/${shopName}`);
    };

    const handleCloseAlert = () => setShowAlert(false);

    return (
        <Container className="mt-5">
            <h1 className="text-center mb-4">Dashboard</h1>
            {message && <Alert variant="warning">{message}</Alert>}
            <Row className="justify-content-center">
                {shops.length > 0 ? (
                    shops.map(shop => (
                        <Col key={shop.id} xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex justify-content-center">
                            <Card style={{ width: '100%' }}>
                                <Card.Body>
                                    <Card.Title>{shop.name}</Card.Title>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleViewShop(shop.name)}
                                    >
                                        View Shop
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <p className="text-center">No shops available</p>
                )}
            </Row>

            {/* Display Messages */}
            <AlertBox show={showAlert} message={message} onClose={handleCloseAlert} />
        </Container>
    );
};

export default Dashboard;