import React, { useState, useEffect, useCallback } from 'react';
import { Table, Button, Modal, Form, Container, Card, Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import AlertBox from '../../components/AlertBox';
import { fetchData, handleAction, verifyToken } from '../../utils/apiUtils';

const Employees = () => {
    const { shopName } = useParams();
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [newEmployeeUsername, setNewEmployeeUsername] = useState('');
    const [newEmployeeRole, setNewEmployeeRole] = useState('');
    const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false);
    const [canAddEmployee, setCanAddEmployee] = useState(false);
    const [canRemoveEmployee, setCanRemoveEmployee] = useState(false);

    const token = localStorage.getItem('token');

    const fetchEmployees = useCallback(async () => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        const result = await fetchData(`/shop/${shopName}/employees`, token);
        if (result.errorMessage) {
            setMessage(result.errorMessage);
            setShowAlert(true);
        } else {
            setEmployees(result);
        }
    }, [shopName, token, navigate]);

    const checkUserPermissions = useCallback(async () => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        const result = await fetchData(`/shop/${shopName}/check-permissions`, token);
        if (result.errorMessage) {
            setMessage(result.errorMessage);
            setShowAlert(true);
        } else {
            if (result.management) {
                setCanAddEmployee(true);
                setCanRemoveEmployee(true);
            } else {
                setMessage('Permission check failed. You do not have the required permissions.');
                setShowAlert(true);
            }
        }
    }, [shopName, token, navigate]);

    const handleAddEmployee = async (e) => {
        e.preventDefault();
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        const result = await handleAction(`/shop/${shopName}/add-employee`, 'POST', {
            username: newEmployeeUsername,
            role: newEmployeeRole
        }, 'Employee added successfully!', fetchEmployees, token);

        if (result.errorMessage) {
            setMessage(result.errorMessage);
            setShowAlert(true);
        } else {
            setMessage(result.successMessage);
            setNewEmployeeUsername('');
            setNewEmployeeRole('');
            setShowAddEmployeeModal(false);
        }
    };

    const handleRemoveEmployee = async (username) => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        const result = await handleAction(`/shop/${shopName}/remove-employee`, 'DELETE', { username }, 'Employee removed successfully!', fetchEmployees, token);

        if (result.errorMessage) {
            setMessage(result.errorMessage);
            setShowAlert(true);
        } else {
            setMessage(result.successMessage);
            setShowAlert(true);
        }
    };

    useEffect(() => {
        fetchEmployees();
        checkUserPermissions();
    }, [fetchEmployees, checkUserPermissions]);

    const handleCloseAlert = () => setShowAlert(false);
    const handleCloseAddEmployeeModal = () => setShowAddEmployeeModal(false);

    return (
        <Container className="mt-5">
            <Card className="p-4">
                <h2>Employees</h2>
                <Row className="mb-3">
                    <Col className="d-flex justify-content-between align-items-center">
                        {canAddEmployee && (
                            <Button onClick={() => setShowAddEmployeeModal(true)} variant="primary">
                                Add Employee
                            </Button>
                        )}
                        <Button variant="secondary" onClick={() => navigate(`/shop/${shopName}`)}>
                            Back to Shop Dashboard
                        </Button>
                    </Col>
                </Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Role</th>
                            {canRemoveEmployee && <th>Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {employees.length > 0 ? (
                            employees.map((employee, index) => (
                                <tr key={index}>
                                    <td>{employee.username}</td>
                                    <td>{employee.role}</td>
                                    {canRemoveEmployee && (
                                        <td>
                                            <Button
                                                variant="danger"
                                                onClick={() => handleRemoveEmployee(employee.username)}
                                            >
                                                Remove
                                            </Button>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={canRemoveEmployee ? "3" : "2"}>No employees found</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <Modal show={showAddEmployeeModal} onHide={handleCloseAddEmployeeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Employee</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleAddEmployee}>
                            <Form.Group controlId="formNewEmployeeUsername">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={newEmployeeUsername}
                                    onChange={e => setNewEmployeeUsername(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formNewEmployeeRole">
                                <Form.Label>Role</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={newEmployeeRole}
                                    onChange={e => setNewEmployeeRole(e.target.value)}
                                    required
                                >
                                    <option value="">Select a role...</option>
                                    <option value="Employee">Employee</option>
                                    <option value="Manager">Manager</option>
                                </Form.Control>
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-3">
                                Add
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </Card>
            <AlertBox show={showAlert} onClose={handleCloseAlert} message={message} />
        </Container>
    );
};

export default Employees;