import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';

const Shop = () => {
    const { shopName } = useParams();
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(`/shop/${shopName}/${path}`);
    };

    return (
        <Container className="mt-5 text-center">
             <h1>{shopName} Dashboard</h1>

            <Row className="mt-5">
                <Col md={4} className="mb-4">
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>Employees</Card.Title>
                            <Card.Text>
                                Manage your shop's employees. Add or remove employees and assign roles.
                            </Card.Text>
                            <Button
                                variant="primary"
                                onClick={() => handleNavigation('employees')}
                            >
                                Go to Employees
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>Stock</Card.Title>
                            <Card.Text>
                                View and manage your shop's inventory. Update quantities and check stock levels.
                            </Card.Text>
                            <Button
                                variant="primary"
                                onClick={() => handleNavigation('stock')}
                            >
                                Go to Stock
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4} className="mb-4">
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Title>Sales</Card.Title>
                            <Card.Text>
                                View sales data for your shop. Analyze performance over various timeframes.
                            </Card.Text>
                            <Button
                                variant="primary"
                                onClick={() => handleNavigation('sales')}
                            >
                                Go to Sales
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Shop;