import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Button, Card, ListGroup, Form, Modal, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AlertBox from '../components/AlertBox';
import { checkIsUserAdmin, fetchData, handleAction } from '../utils/apiUtils';

const Admin = () => {
    const [shops, setShops] = useState([]);
    const [users, setUsers] = useState([]);
    const [systemInfo, setSystemInfo] = useState(null);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isManagingUsers, setIsManagingUsers] = useState(false);
    const [isManagingShops, setIsManagingShops] = useState(false);
    const [newShopName, setNewShopName] = useState('');
    const [newShopDescription, setNewShopDescription] = useState('');
    const [newShopBotDbLink, setNewShopDbLink] = useState('');
    const [selectedUserId, setSelectedUserId] = useState('');
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    const fetchSystemInfo = useCallback(async () => {
        try {
            const data = await fetchData('/admin/sysinfo', token);
            setSystemInfo(data);
        } catch (error) {
            setMessage('Failed to fetch system information');
            setShowAlert(true);
        }
    }, [token]);

    const checkAdminStatus = useCallback(async () => {
        const result = await checkIsUserAdmin(token, navigate);

        if (!result.isAdmin) {
            setMessage(result.errorMessage);
            setShowAlert(true);
            return;
        }

        // Proceed to fetch data only if admin status is verified
        try {
            const shopsData = await fetchData('/admin/shops', token);
            setShops(shopsData);

            const usersData = await fetchData('/admin/users', token);
            setUsers(usersData);

            fetchSystemInfo();
        } catch (error) {
            setMessage('Failed to fetch initial data');
            setShowAlert(true);
        }
    }, [token, navigate, fetchSystemInfo]);

    useEffect(() => {
        checkAdminStatus();
    }, [checkAdminStatus]);

    const handleAddShop = async (e) => {
        e.preventDefault();
        console.log('Adding shop...');
        try {
            const result = await handleAction(
                '/admin/add-shop',
                'post',
                { name: newShopName, description: newShopDescription, botDbLink: newShopBotDbLink },
                'Shop added successfully!',
                async () => {
                    const shopsData = await fetchData('/admin/shops', token);
                    setShops(shopsData); // Refresh data
                },
                token
            );
            console.log('Add shop result:', result);
            if (result.successMessage) {
                setMessage(result.successMessage);
                setShowAlert(true);
            } else if (result.errorMessage) {
                setMessage(result.errorMessage);
                setShowAlert(true);
            }
        } catch (error) {
            setMessage('Failed to add shop');
            setShowAlert(true);
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        console.log('Adding user...');
        try {
            const result = await handleAction(
                '/admin/add-user-account',
                'post',
                { username: newUsername, password: newPassword },
                'User account created successfully!',
                async () => {
                    const usersData = await fetchData('/admin/users', token);
                    setUsers(usersData); // Refresh data
                },
                token
            );
            console.log('Add user result:', result);
            if (result.successMessage) {
                setMessage(result.successMessage);
                setShowAlert(true);
            } else if (result.errorMessage) {
                setMessage(result.errorMessage);
                setShowAlert(true);
            }
        } catch (error) {
            setMessage('Failed to add user');
            setShowAlert(true);
        }
    };

    const handleForcePasswordChange = async (e) => {
        e.preventDefault();
        console.log('Forcing password change...');
        try {
            const result = await handleAction(
                '/admin/force-change-password',
                'post',
                { userId: selectedUserId, newPassword },
                'Password changed successfully!',
                async () => {
                    setShowPasswordModal(false);
                    setSelectedUserId('');
                    setNewPassword('');
                    const usersData = await fetchData('/admin/users', token);
                    setUsers(usersData); // Refresh data
                },
                token
            );
            console.log('Force password change result:', result);
            if (result.successMessage) {
                setMessage(result.successMessage);
                setShowAlert(true);
            } else if (result.errorMessage) {
                setMessage(result.errorMessage);
                setShowAlert(true);
            }
        } catch (error) {
            setMessage('Failed to change password');
            setShowAlert(true);
        }
    };

    const handleSignOutAllUsers = async () => {
        console.log('Signing out all users...');
        try {
            const result = await handleAction(
                '/admin/sign-out-all',
                'post',
                null,
                'All users signed out successfully!',
                async () => {
                    const usersData = await fetchData('/admin/users', token);
                    setUsers(usersData); // Refresh data
                },
                token
            );
            console.log('Sign out all users result:', result);
            if (result.successMessage) {
                setMessage(result.successMessage);
                setShowAlert(true);
            } else if (result.errorMessage) {
                setMessage(result.errorMessage);
                setShowAlert(true);
            }
        } catch (error) {
            setMessage('Failed to sign out all users');
            setShowAlert(true);
        }
    };

    const handleRemoveShop = async (shopId) => {
        console.log('Removing shop...');
        try {
            const result = await handleAction(
                `/admin/delete-shop/${shopId}`,
                'DELETE',
                null,
                'Shop removed successfully!',
                async () => {
                    const shopsData = await fetchData('/admin/shops', token);
                    setShops(shopsData); // Refresh data
                },
                token
            );
            console.log('Remove shop result:', result);
            if (result.successMessage) {
                setMessage(result.successMessage);
                setShowAlert(true);
            } else if (result.errorMessage) {
                setMessage(result.errorMessage);
                setShowAlert(true);
            }
        } catch (error) {
            setMessage('Failed to remove shop');
            setShowAlert(true);
        }
    };

    const handleViewShop = (shop) => {
        navigate(`/shop/${shop.name}`);
    };

    const handleCloseAlert = () => setShowAlert(false);

    return (
        <Container className="mt-5">
            {/* Manage Shops Card */}
            <Row>
                <Col>
                    <Card className="p-4 mb-4">
                        <h2 className="text-center">Manage Shops</h2>
                        {isManagingShops ? (
                            <Form onSubmit={handleAddShop}>
                                <Form.Group controlId="formNewShopName">
                                    <Form.Label>Shop Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newShopName}
                                        onChange={(e) => setNewShopName(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formNewShopDescription">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newShopDescription}
                                        onChange={(e) => setNewShopDescription(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formNewShopBotDbLink">
                                    <Form.Label>Bot Database Link</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newShopBotDbLink}
                                        onChange={(e) => setNewShopDbLink(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-3">
                                    Add Shop
                                </Button>
                                <Button variant="secondary" onClick={() => setIsManagingShops(false)} className="mt-3">
                                    Back
                                </Button>
                            </Form>
                        ) : (
                            <>
                                <Button variant="primary" onClick={() => setIsManagingShops(true)}>
                                    Add Shop
                                </Button>
                                <ListGroup className="mt-3">
                                    {shops.map(shop => (
                                        <ListGroup.Item key={shop.id}>
                                            <h5>{shop.name}</h5>
                                            <p>{shop.description}</p>
                                            <Button
                                                variant="info"
                                                onClick={() => handleViewShop(shop)}
                                                className="me-2"
                                            >
                                                View Shop
                                            </Button>
                                            <Button
                                                variant="danger"
                                                onClick={() => handleRemoveShop(shop.id)}
                                            >
                                                Remove Shop
                                            </Button>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>

            {/* Manage User Accounts Card */}
            <Row>
                <Col>
                    <Card className="p-4 mb-4">
                        <h2 className="text-center">Manage User Accounts</h2>
                        {isManagingUsers ? (
                            <Form onSubmit={handleAddUser}>
                                <Form.Group controlId="formNewUsername">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={newUsername}
                                        onChange={(e) => setNewUsername(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formNewPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="mt-3">
                                    Add User
                                </Button>
                                <Button variant="secondary" onClick={() => setIsManagingUsers(false)} className="mt-3">
                                    Back
                                </Button>
                            </Form>
                        ) : (
                            <>
                                <Button variant="primary" onClick={() => setIsManagingUsers(true)}>
                                    Add User
                                </Button>
                                <Button variant="danger" onClick={handleSignOutAllUsers} className="mt-3">
                                    Sign Out All Users
                                </Button>
                                <ListGroup className="mt-3">
                                    {users.map(user => (
                                        <ListGroup.Item key={user.id}>
                                            <h5>{user.username}</h5>
                                            <Button
                                                variant="warning"
                                                onClick={() => {
                                                    setSelectedUserId(user.id);
                                                    setShowPasswordModal(true);
                                                }}
                                            >
                                                Force Password Change
                                            </Button>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </>
                        )}
                    </Card>
                </Col>
            </Row>

            {/* System Information Card */}
            {systemInfo && (
                <Row>
                    <Col>
                        <Card className="p-4 mb-4">
                            <h2 className="text-center">System Information</h2>
                            <ListGroup>
                                <ListGroup.Item><strong>System Uptime:</strong> {systemInfo.systemUptime}</ListGroup.Item>
                                <ListGroup.Item><strong>CPU Usage:</strong> {systemInfo.cpuUsage}%</ListGroup.Item>
                                <ListGroup.Item><strong>RAM Usage:</strong> {systemInfo.ramUsage}</ListGroup.Item>
                                <ListGroup.Item>
                                    <strong>SSD Usage:</strong>
                                    <ul>
                                        {systemInfo.ssdUsage.map((di) => (
                                            <li key={di.fs}>{di.fs}: {di.used} GB / {di.size} GB</li>
                                        ))}
                                    </ul>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </Col>
                </Row>
            )}

            {/* Password Change Modal */}
            <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleForcePasswordChange}>
                        <Form.Group controlId="formNewPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="mt-3">
                            Change Password
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Display Messages */}
            <AlertBox show={showAlert} message={message} onClose={handleCloseAlert} />
        </Container>
    );
};

export default Admin;