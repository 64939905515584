import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/global.css'
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Import Axios Server
import axios from 'axios';

// Import Components & Utils
import NavbarComponent from './components/Navbar';
import Footer from './components/Footer';
import { getAPIURL } from './utils/apiUtils';

// Import Pages
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Admin from './pages/Admin';
import Shop from './pages/Shop';
import Employees from './pages/shop/Employees';
import Stock from './pages/shop/Stock';
import Sales from './pages/shop/Sales';

// Function to fetch user details
const fetchUserDetails = async (token) => {
    try {
        const response = await axios.get(`${getAPIURL()}/me`, {
            headers: { 'x-access-token': token }
        });
        return response.data;
    } catch (error) {
        console.error("Failed to fetch user details:", error);
        return null;
    }
};

const App = () => {
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            fetchUserDetails(token).then(userDetails => {
                if (userDetails) {
                    setIsAuthenticated(true);
                    setIsAdmin(userDetails.is_admin);
                    setUserName(userDetails.username);
                } else {
                    localStorage.removeItem('token');
                }
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <div className="app-container">
                <NavbarComponent isAuthenticated={isAuthenticated} isAdmin={isAdmin} userName={userName} />

                <main className="content">
                    <Routes>
                        <Route
                            path="/"
                            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/login"
                            element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />}
                        />
                        <Route
                            path="/dashboard"
                            element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/profile"
                            element={isAuthenticated ? <Profile /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/admin"
                            element={isAuthenticated ? (isAdmin ? <Admin /> : <Navigate to="/dashboard" />) : <Navigate to="/login" />}
                        />
                        <Route
                            path="/shop/:shopName"
                            element={isAuthenticated ? <Shop /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/shop/:shopName/stock"
                            element={isAuthenticated ? <Stock /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/shop/:shopName/employees"
                            element={isAuthenticated ? <Employees /> : <Navigate to="/login" />}
                        />
                        <Route
                            path="/shop/:shopName/sales"
                            element={isAuthenticated ? <Sales /> : <Navigate to="/login" />}
                        />
                    </Routes>
                </main>

                <Footer />
            </div>
        </Router>
    );
};

export default App;