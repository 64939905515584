import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer>
            <Container>
                <Row>
                    <Col className="text-center">
                        <p className="mb-1">www.roleplay.co.uk</p>
                        <p className="mb-0">
                            RPUK Management & Staff are not responsible for this site, as it is run by Mason Edge for the Shop Bot
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;