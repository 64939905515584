import React, { useState, useEffect, useCallback } from 'react';
import { Table, Container, Card, Form, Pagination, Row, Col, Button, Modal } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchData, verifyToken, handleAction } from '../../utils/apiUtils';
import AlertBox from '../../components/AlertBox';

const Sales = () => {
    const { shopName } = useParams();
    const navigate = useNavigate();
    const [sales, setSales] = useState([]);
    const [totalSales, setTotalSales] = useState(0);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBoxText, setSearchBoxText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [salesDataSummary, setSalesDataSummary] = useState({
        last24Hours: 0,
        last7Days: 0,
        last14Days: 0,
        last30Days: 0,
        lifetime: 0
    });
    const [bestSellingItems, setBestSellingItems] = useState({
        last24Hours: { item: 'N/A', quantity: 0 },
        last7Days: { item: 'N/A', quantity: 0 },
        last14Days: { item: 'N/A', quantity: 0 },
        last30Days: { item: 'N/A', quantity: 0 },
        lifetime: { item: 'N/A', quantity: 0 }
    });
    const [items, setItems] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newSale, setNewSale] = useState({
        itemName: '',
        quantity: '',
        cost: '',
        date: '',
        time: ''
    });
    const itemsPerPage = 10;
    const token = localStorage.getItem('token');

    // Fetch sales data with optional search term
    const fetchSales = useCallback(async (page, search = '') => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        try {
            const salesData = await fetchData(`/shop/${shopName}/sales?limit=${itemsPerPage}&page=${page}&search=${search}`, token);
            setSales(salesData);

            const totalCount = await fetchData(`/shop/${shopName}/sales-count?search=${search}`, token);
            setTotalSales(totalCount);

            setTotalPages(Math.ceil(totalCount / itemsPerPage));
        } catch (error) {
            console.error('Error fetching sales data:', error);
            setMessage('Failed to fetch sales data');
            setShowAlert(true);
        }
    }, [shopName, token, navigate, itemsPerPage]);

    // Fetch sales summary data
    const fetchSalesSummary = useCallback(async () => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        try {
            const currentTime = new Date();
            const startTime24Hours = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000).toISOString();
            const startTime7Days = new Date(currentTime.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
            const startTime14Days = new Date(currentTime.getTime() - 14 * 24 * 60 * 60 * 1000).toISOString();
            const startTime30Days = new Date(currentTime.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString();
            const lifetimeStartTime = '1970-01-01T00:00:00Z';

            const summaryData = await fetchData(`/shop/${shopName}/sales-summary?startTime=${startTime24Hours}`, token);
            const last7DaysData = await fetchData(`/shop/${shopName}/sales-summary?startTime=${startTime7Days}`, token);
            const last14DaysData = await fetchData(`/shop/${shopName}/sales-summary?startTime=${startTime14Days}`, token);
            const last30DaysData = await fetchData(`/shop/${shopName}/sales-summary?startTime=${startTime30Days}`, token);
            const lifetimeData = await fetchData(`/shop/${shopName}/sales-summary?startTime=${lifetimeStartTime}`, token);

            setSalesDataSummary({
                last24Hours: summaryData.total,
                last7Days: last7DaysData.total,
                last14Days: last14DaysData.total,
                last30Days: last30DaysData.total,
                lifetime: lifetimeData.total
            });
        } catch (error) {
            console.error('Error fetching sales summary:', error);
            setMessage('Failed to fetch sales summary');
            setShowAlert(true);
        }
    }, [shopName, token, navigate]);

    // Fetch best-selling items
    const fetchBestSellingItems = useCallback(async () => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        try {
            const currentTime = new Date();
            const startTime24Hours = new Date(currentTime.getTime() - 24 * 60 * 60 * 1000).toISOString();
            const startTime7Days = new Date(currentTime.getTime() - 7 * 24 * 60 * 60 * 1000).toISOString();
            const startTime14Days = new Date(currentTime.getTime() - 14 * 24 * 60 * 60 * 1000).toISOString();
            const startTime30Days = new Date(currentTime.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString();
            const lifetimeStartTime = '1970-01-01T00:00:00Z';

            const fetchItemData = async (startTime) => {
                try {
                    const data = await fetchData(`/shop/${shopName}/best-seller?startTime=${startTime}`, token);
                    return data;
                } catch (error) {
                    console.error('Error fetching best-selling items:', error);
                    setMessage('Failed to fetch best-selling items');
                    setShowAlert(true);
                    return { Item: 'N/A', totalQuantity: 0 };
                }
            };

            const [last24HoursData, last7DaysData, last14DaysData, last30DaysData, lifetimeData] = await Promise.all([
                fetchItemData(startTime24Hours),
                fetchItemData(startTime7Days),
                fetchItemData(startTime14Days),
                fetchItemData(startTime30Days),
                fetchItemData(lifetimeStartTime)
            ]);

            setBestSellingItems({
                last24Hours: last24HoursData,
                last7Days: last7DaysData,
                last14Days: last14DaysData,
                last30Days: last30DaysData,
                lifetime: lifetimeData
            });
        } catch (error) {
            console.error('Error fetching best-selling items:', error);
            setMessage('Failed to fetch best-selling items');
            setShowAlert(true);
        }
    }, [shopName, token, navigate]);

    // Fetch items for the addSale dropdown
    const fetchItems = useCallback(async () => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        try {
            const itemList = await fetchData(`/shop/${shopName}/stock`, token);
            setItems(itemList);
        } catch (error) {
            console.error('Error fetching items:', error);
            setMessage('Failed to fetch items');
            setShowAlert(true);
        }
    }, [shopName, token, navigate]);

    useEffect(() => {
        fetchSales(currentPage, searchTerm);
        fetchSalesSummary();
        fetchBestSellingItems();
        fetchItems();
    }, [fetchSales, fetchSalesSummary, fetchBestSellingItems, fetchItems, currentPage, searchTerm]);

    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) return;
        setCurrentPage(pageNumber);
    };

    const handleCloseAlert = () => setShowAlert(false);

    const handleSubmitSale = async (event) => {
        event.preventDefault();
        const { itemName, quantity, cost, date, time } = newSale;
        if (!itemName || !quantity || !cost || !date || !time) {
            setMessage('All fields are required');
            setShowAlert(true);
            return;
        }

        const data = {
            itemName,
            quantity: parseFloat(quantity),
            cost: parseFloat(cost),
            date,
            time
        };

        const { errorMessage } = await handleAction(`/shop/${shopName}/add-sale`, 'POST', data, 'Sale added successfully', () => fetchSales(currentPage, searchTerm), token);

        if (errorMessage) {
            console.error('Error adding sale:', errorMessage);
            setMessage('Failed to add sale');
            setShowAlert(true);
        } else {
            setShowModal(false);
        }
    };

    // Render the modal for adding a sale
    const renderModal = () => (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Sale</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmitSale}>
                    <Form.Group controlId="itemName">
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control
                            as="select"
                            value={newSale.itemName}
                            onChange={e => setNewSale({ ...newSale, itemName: e.target.value })}
                        >
                            <option value="">Select an item</option>
                            {items.map((item, index) => (
                                <option key={index} value={item.Item}>
                                    {item.Item}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="quantity">
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                            type="number"
                            step="1.0"
                            value={newSale.quantity}
                            onChange={e => setNewSale({ ...newSale, quantity: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="cost">
                        <Form.Label>Cost</Form.Label>
                        <Form.Control
                            type="number"
                            step="1.0"
                            value={newSale.cost}
                            onChange={e => setNewSale({ ...newSale, cost: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="date">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="date"
                            value={newSale.date}
                            onChange={e => setNewSale({ ...newSale, date: e.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="time">
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                            type="time"
                            value={newSale.time}
                            onChange={e => setNewSale({ ...newSale, time: e.target.value })}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        Add Sale
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );

    // Generate page numbers to display
    const generatePageNumbers = () => {
        const maxButtons = 5; // Maximum number of buttons to display
        const range = [];
        const delta = Math.floor(maxButtons / 2); // Number of pages to show around the current page

        if (totalPages <= maxButtons) {
            for (let i = 1; i <= totalPages; i++) {
                range.push(i);
            }
        } else {
            range.push(1);
            const start = Math.max(2, currentPage - delta);
            const end = Math.min(totalPages - 1, currentPage + delta);

            for (let i = start; i <= end; i++) {
                range.push(i);
            }

            if (totalPages > 1 && (end < totalPages - 1)) {
                range.push(totalPages);
            }
        }
        return range;
    };

    const pageNumbers = generatePageNumbers();

    return (
        <Container className="mt-5">
            <Row>
                <Col md={8}>
                    <Card className="p-4">
                        <h2>Sales</h2>
                        <Form className="mb-4 d-flex">
                            <Form.Group controlId="search" className="d-flex flex-grow-1">
                                <Form.Control
                                    type="text"
                                    placeholder="Search for a sale..."
                                    value={searchBoxText}
                                    onChange={e => setSearchBoxText(e.target.value)}
                                    className="me-2"
                                />
                                <Button variant="primary" onClick={() => setSearchTerm(searchBoxText)}>
                                    Search
                                </Button>
                            </Form.Group>
                        </Form>

                        <Table striped bordered hover className="mt-3">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Quantity</th>
                                    <th>Profit</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sales.length > 0 ? (
                                    sales.map((sale, index) => (
                                        <tr key={index}>
                                            <td>{sale.Item}</td>
                                            <td>{sale.Quantity}</td>
                                            <td>£{typeof sale.Profit === 'number' ? sale.Profit.toFixed(2) : 'N/A'}</td>
                                            <td>{new Date(sale.Date).toLocaleDateString()}</td>
                                            <td>{sale.Time}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No sales found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>

                        <Button
                            variant="primary"
                            onClick={() => setShowModal(true)}
                            className="mb-4"
                        >
                            Add Sale
                        </Button>

                        {totalSales > 0 && (
                            <Pagination className="mt-4">
                                <Pagination.Prev
                                    onClick={() => handlePageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                />
                                {pageNumbers.map((pageNumber, index) => (
                                    <Pagination.Item
                                        key={index}
                                        active={pageNumber === currentPage}
                                        onClick={() => handlePageChange(pageNumber)}
                                    >
                                        {pageNumber}
                                    </Pagination.Item>
                                ))}
                                <Pagination.Next
                                    onClick={() => handlePageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                />
                            </Pagination>
                        )}
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="p-4">
                        <h2>Sales</h2>
                        <ul>
                            <li>Last 24 hours: £{salesDataSummary.last24Hours.toFixed(2)}</li>
                            <li>Last 7 days: £{salesDataSummary.last7Days.toFixed(2)}</li>
                            <li>Last 14 days: £{salesDataSummary.last14Days.toFixed(2)}</li>
                            <li>Last 30 days: £{salesDataSummary.last30Days.toFixed(2)}</li>
                            <li>Total lifetime: £{salesDataSummary.lifetime.toFixed(2)}</li>
                        </ul>
                    </Card>
                    <Card className="p-4 mt-4">
                        <h2>Best Selling</h2>
                        <ul>
                            <li>
                                Last 24 hours: {bestSellingItems?.last24Hours?.Item || 'N/A'}
                                ({parseFloat(bestSellingItems?.last24Hours?.totalQuantity).toFixed(0)} sold)
                            </li>
                            <li>
                                Last 7 days: {bestSellingItems?.last7Days?.Item || 'N/A'}
                                ({parseFloat(bestSellingItems?.last7Days?.totalQuantity).toFixed(0)} sold)
                            </li>
                            <li>
                                Last 14 days: {bestSellingItems?.last14Days?.Item || 'N/A'}
                                ({parseFloat(bestSellingItems?.last14Days?.totalQuantity).toFixed(0)} sold)
                            </li>
                            <li>
                                Last 30 days: {bestSellingItems?.last30Days?.Item || 'N/A'}
                                ({parseFloat(bestSellingItems?.last30Days?.totalQuantity).toFixed(0)} sold)
                            </li>
                            <li>
                                Total lifetime: {bestSellingItems?.lifetime?.Item || 'N/A'}
                                ({parseFloat(bestSellingItems?.lifetime?.totalQuantity).toFixed(0)} sold)
                            </li>
                        </ul>
                    </Card>

                    <Button
                        variant="secondary"
                        onClick={() => navigate(`/shop/${shopName}`)}
                        className="w-100 mt-3"
                    >
                        Back to Shop Dashboard
                    </Button>
                </Col>
            </Row>
            <AlertBox show={showAlert} onClose={handleCloseAlert} message={message} />
            {renderModal()}
        </Container>
    );
};

export default Sales;