import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Card } from 'react-bootstrap';
import { getAPIURL } from '../utils/apiUtils';
import AlertBox from '../components/AlertBox';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${getAPIURL()}/login`, { username, password });
            localStorage.setItem('token', response.data.token);
            window.location.reload();
        } catch (error) {
            let errorMessage = 'An unexpected error occurred. Please try again later.';

            if (error.response) {
                if (error.response.status === 401) {
                    errorMessage = 'Invalid credentials. Please check your username and password.';
                } else if (error.response.status === 500) {
                    errorMessage = 'Server error. Please try again later.';
                }
            } else if (error.request) {
                errorMessage = 'Could not connect to the server. Please check your network connection.';
            } else {
                errorMessage = 'An error occurred while setting up the request.';
            }

            setMessage(errorMessage);
            setShowAlert(true);
        }
    };

    const handleCloseAlert = () => setShowAlert(false);

    return (
        <Container className="mt-5">
            <Card className="p-4">
                <h1 className="text-center">Login</h1>
                <Form onSubmit={handleLogin}>
                    <Form.Group controlId="formUsername">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId="formPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="mt-3">
                        Login
                    </Button>
                </Form>
            </Card>
            <AlertBox show={showAlert} message={message} onClose={handleCloseAlert} />
        </Container>
    );
};

export default Login;