import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, ListGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { fetchData, handleAction, verifyToken } from '../utils/apiUtils';
import { format } from 'date-fns';
import AlertBox from '../components/AlertBox';

const Profile = () => {
    const [userInfo, setUserInfo] = useState(null);
    const [shops, setShops] = useState([]);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const { isVerified, errorMessage } = await verifyToken(token, navigate);
                if (!isVerified) {
                    setMessage(errorMessage);
                    setShowAlert(true);
                    return;
                }

                const userResult = await fetchData('/me', token);
                setUserInfo(userResult);

                const shopsResult = await fetchData('/shops', token);
                setShops(shopsResult);
            } catch (error) {
                setMessage('Failed to fetch data.');
                setShowAlert(true);
            }
        };

        fetchUserData();
    }, [navigate, token]);

    const handlePasswordChange = async (e) => {
        e.preventDefault();

        try {
            const result = await handleAction(
                '/update-password',
                'POST',
                { oldPassword, newPassword },
                'Password changed successfully!',
                null,
                token
            );
            setMessage(result.successMessage || 'Failed to change password.');
        } catch (error) {
            setMessage('Failed to change password.');
        }
        setShowAlert(true);
    };

    const formatTimestamp = (timestamp) => {
        try {
            const date = new Date(timestamp);
            return `${format(date, 'MM/dd/yyyy')} ${format(date, 'HH:mm:ss')}`;
        } catch (error) {
            console.error('Date parsing error:', error);
            return 'Date not available';
        }
    };

    const handleCloseAlert = () => setShowAlert(false);

    return (
        <Container className="mt-5">
            <Card className="p-4">
                <h1 className="text-center">Profile</h1>
                {userInfo && (
                    <Card.Body>
                        <h3>User Information</h3>
                        <p><strong>Username:</strong> {userInfo.username}</p>
                        <p><strong>Created at:</strong> {userInfo.created_at ? formatTimestamp(userInfo.created_at) : 'Date not available'}</p>

                        <h3 className="mt-4">List of Shops Access</h3>
                        {shops.length > 0 ? (
                            <ListGroup>
                                {shops.map((shop) => (
                                    <ListGroup.Item key={shop.id}>{shop.name}</ListGroup.Item>
                                ))}
                            </ListGroup>
                        ) : (
                            <p>No shops access available.</p>
                        )}

                        <h3 className="mt-4">Change Password</h3>
                        <Form onSubmit={handlePasswordChange} className="mt-4">
                            <Form.Group controlId="formOldPassword">
                                <Form.Label>Old Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formNewPassword" className="mt-3">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" className="mt-3">
                                Change Password
                            </Button>
                        </Form>
                    </Card.Body>
                )}
                <Button variant="secondary" onClick={() => navigate('/dashboard')} className="mt-3">
                    Back to Dashboard
                </Button>
            </Card>
            <AlertBox show={showAlert} message={message} onClose={handleCloseAlert} />
        </Container>
    );
};

export default Profile;