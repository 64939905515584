import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { getAPIURL } from '../utils/apiUtils';
import axios from 'axios';
import '../styles/Navbar.css';

const NavbarComponent = ({ isAuthenticated, isAdmin, userName }) => {
    const handleLogout = async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            // If there's no token, just redirect to login
            window.location.href = '/login';
            return;
        }

        try {
            // Send a POST request to the logout route
            await axios.post(`${getAPIURL()}/logout`, {}, {
                headers: { 'x-access-token': token }
            });

            // Remove the token from local storage
            localStorage.removeItem('token');

            // Redirect to the login page
            window.location.href = '/login';
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <Navbar className="navbar navbar-expand-lg fixed-top bg-light navbar-light" expand="lg">
            <div className="container">
                <Navbar.Brand as={Link} to="/" className="navbar-brand">
                    {isAuthenticated ? `Welcome, ${userName}` : 'Welcome'}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarSupportedContent">
                    <i className="fas fa-bars"></i>
                </Navbar.Toggle>
                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav className="ms-auto align-items-center">
                        {isAuthenticated ? (
                            <>
                                <Nav.Item>
                                    <Nav.Link as={Link} to="/dashboard" className="nav-link mx-2">
                                        <i className="fas fa-shopping-bag pe-2"></i>Dashboard
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link as={Link} to="/profile" className="nav-link mx-2">
                                        <i className="fas fa-user pe-2"></i>Profile
                                    </Nav.Link>
                                </Nav.Item>
                                {isAdmin ? (
                                    <Nav.Item>
                                        <Nav.Link as={Link} to="/admin" className="nav-link mx-2">
                                            <i className="fas fa-code pe-2"></i>Admin Page
                                        </Nav.Link>
                                    </Nav.Item>
                                ): null}
                                <Nav.Item className="ms-3">
                                    <Button onClick={handleLogout} className="btn-black btn-rounded">
                                        Logout
                                    </Button>
                                </Nav.Item>
                            </>
                        ) : null}
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

export default NavbarComponent;