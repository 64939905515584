import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Form, Row, Col, Table, Container, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchData, handleAction, verifyToken } from '../../utils/apiUtils';
import AlertBox from '../../components/AlertBox';
import '../../styles/Stock.css';

const Stock = () => {
    const { shopName } = useParams();
    const navigate = useNavigate();
    const [stock, setStock] = useState([]);
    const [message, setMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showEditStockModal, setShowEditStockModal] = useState(false);
    const [editedStock, setEditedStock] = useState([]);
    const token = localStorage.getItem('token');

    const fetchStock = useCallback(async () => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        const result = await fetchData(`/shop/${shopName}/stock`, token);
        if (result.errorMessage) {
            setMessage(result.errorMessage);
            setShowAlert(true);
        } else {
            setStock(result);
        }
    }, [shopName, token, navigate]);

    const handleEditStock = () => {
        setEditedStock(stock.map(item => ({ ...item })));
        setShowEditStockModal(true);
    };

    const handleStockChange = (index, newQuantity) => {
        const updatedStock = [...editedStock];
        updatedStock[index].Quantity = newQuantity;
        setEditedStock(updatedStock);
    };

    const handleSubmitEditStock = async () => {
        const { isVerified, errorMessage } = await verifyToken(token, navigate);
        if (!isVerified) {
            setMessage(errorMessage);
            setShowAlert(true);
            return;
        }

        const result = await handleAction(`/shop/${shopName}/update-stock`, 'PUT', editedStock, 'Stock updated successfully!', fetchStock, token);

        if (result.errorMessage) {
            setMessage(result.errorMessage);
            setShowAlert(true);
        } else {
            setMessage(result.successMessage);
            setShowEditStockModal(false);
        }
    };

    useEffect(() => {
        fetchStock();
    }, [fetchStock]);

    const handleCloseAlert = () => setShowAlert(false);
    const handleCloseEditStockModal = () => setShowEditStockModal(false);

    return (
        <Container className="mt-5">
            <Card className="p-4">
                <h2>Stock</h2>

                <Row className="mb-3">
                    <Col>
                        <div className="d-flex justify-content-between">
                            <Button onClick={handleEditStock} variant="primary">
                                Edit Stock
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => navigate(`/shop/${shopName}`)}
                                className="me-2"
                            >
                                Back to Shop Dashboard
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Form className="mb-4">
                    <Row className="align-items-center">
                        <Col xs={12}>
                            <Form.Group controlId="search">
                                <Form.Control
                                    type="text"
                                    placeholder="Search for an item..."
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                    className="w-100"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>

                <Table striped bordered hover className="mt-3">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stock.length > 0 ? (
                            stock
                                .filter(item => item.Item.toLowerCase().includes(searchTerm.toLowerCase()))
                                .map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.Item}</td>
                                        <td>
                                            {showEditStockModal ? (
                                                <Form.Control
                                                    type="number"
                                                    value={editedStock[index]?.Quantity || item.Quantity}
                                                    onChange={(e) => handleStockChange(index, e.target.value)}
                                                />
                                            ) : (
                                                item.Quantity
                                            )}
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan="2">No stock available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                <Modal show={showEditStockModal} onHide={handleCloseEditStockModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Stock</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Row className="g-3">
                                {editedStock.length > 0 ? (
                                    editedStock
                                        .filter(item => item.Item.toLowerCase().includes(searchTerm.toLowerCase()))
                                        .map((item, index) => (
                                            <Col md={3} key={index}>
                                                <Form.Group>
                                                    <Form.Label>{item.Item}</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={item.Quantity}
                                                        onChange={(e) => handleStockChange(index, e.target.value)}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        ))
                                ) : (
                                    <Col>
                                        <p>No stock available</p>
                                    </Col>
                                )}
                            </Row>
                        </div>
                        <Button variant="primary" onClick={handleSubmitEditStock} className="mt-3">
                            Save Changes
                        </Button>
                    </Modal.Body>
                </Modal>
            </Card>
            <AlertBox show={showAlert} onClose={handleCloseAlert} message={message} />
        </Container>
    );
};

export default Stock;